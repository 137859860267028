import { render, staticRenderFns } from "./GithubButton.vue?vue&type=template&id=556c237b&scoped=true&lang=pug&"
import script from "./GithubButton.vue?vue&type=script&lang=js&"
export * from "./GithubButton.vue?vue&type=script&lang=js&"
import style0 from "./GithubButton.vue?vue&type=style&index=0&id=556c237b&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "556c237b",
  null
  
)

export default component.exports